// src/accessControl.js

export const storeOperationsUsers = [
  'Jboltin',
  'Ewinters',
  'jnauertc',
  'Knickell',
  'Zakin',
  'store5302',
  'sonic6715',
  'store1022',
  'store1212',
  'sonic3645',
  'store6744',
  'sonic6841',
  'stutton',
  'psexton',
  'store1070',
  'store1074',
  'store3430',
  'store3694',
  'pcartwright',
  'ncribb',
  'amoss',
  'cswafford',
  'store1046',
  'store1067',
  'store1078',
  'store1116',
  'store1770',
  'store4176',
  'store4498',
  'store4906',
  'store4925',
  'store5362',
  'store5363',
  'sonic6989',
];